.hero {
    text-align: center;
    background-color: #000000;
    /* Dark background */
    color: #FFFFFF;
    /* White text */
}

.hero h1 {
    font-size: 36px;
    color: #FFC107;
    /* Yellow */
    margin-bottom: 10px;
}

.hero p {
    font-size: 18px;
    margin-bottom: 20px;
    color: #FFFFFF;
}
