.header {
    display: flex;
    align-items: center;
    padding: 10px 50px;
    background-color: #000000;
    color: #FFC107;
}

.logo {
    flex-shrink: 0;
    /* Keeps the logo size fixed */
}

.logo img {
    width: 150px;
}

.hero {
    flex-grow: 1;
    /* Allows the hero section to expand and center itself */
    text-align: center;
}

.hero h1 {
    font-size: 28px;
    color: #FFC107;
    margin: 10px 0;
}

.hero p {
    font-size: 16px;
    color: #FFC107;
    margin: 0;
}