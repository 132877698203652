.footer {
    padding: 20px;
    background-color: #000000;
    color: white;
    text-align: center;
}

.footer .social-links {
    margin-top: 10px;
}

.footer .social-links a {
    color: white;
    margin: 0 10px;
    text-decoration: none;
}