body {
  font-family: Arial, sans-serif;
  background-color: #000000;
  color: #000000;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3 {
  color: #FFC107;
}

button,
.cta-button {
  background-color: #FFC107;
  color: #000000;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

button:hover,
.cta-button:hover {
  background-color: #e0a800;
}

a {
  color: #FFC107;
  text-decoration: none;
}

a:hover {
  color: #e0a800;
}