.signup-section {
    padding: 60px 20px;
    text-align: center;
    background-color: #FFC107;
}

.signup-section h2 {
    font-size: 28px;
    margin-bottom: 10px;
}

.signup-section p {
    font-size: 16px;
    margin-bottom: 20px;
}

.signup-section form {
    display: inline-block;
}

.signup-section input[type="email"],
.signup-section select {
    padding: 10px;
    font-size: 16px;
    width: 300px;
    margin: 10px 0;
    display: block;
}

.signup-section select {
    margin-top: 10px;
}

.signup-section button {
    padding: 10px 20px;
    background-color: #000000;
    color: white;
    font-size: 16px;
    border: none;
    cursor: pointer;
}

.notify-button:hover {
    background-color: #000000;
    color: #FFC107;
}
